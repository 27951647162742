<template>
  <div class="coutents">
    <!-- 导航 -->
<!--    <div class="nav">-->
<!--      <div class="type">-->
<!--        <span>-->
<!--          <i class="iconfont icon-feneli"></i>-->
<!--          全部产品分类-->
<!--        </span>-->
<!--      </div>-->
<!--      &lt;!&ndash; <ul class="nav-type">-->
<!--        <li class="nav-item" v-for="item in nav" :key="item.id">-->
<!--          {{ item.type }}-->
<!--        </li>-->
<!--      </ul> &ndash;&gt;-->
<!--    </div>-->
    <!-- 是个轮播 -->
    <div class="banner">
      <div class="img-box">
        <!-- <img
          src="/home/yinyou/tomcat/webapps/picture/0118887fc6b34b86954a5f5f551903c0.jpg"
          alt=""
        /> -->
        <!-- <img src="@/static/imgs/index_banner01.jpg" alt="" /> -->

        <swiper class="swiper" :options="swiperOption">
          <swiper-slide
            class="swiper-slide"
            v-for="banner in homeBannerList"
            :key="banner.id"
          >
            <img :src="banner.extendColumn1" class="swiper-lazy" />
            <div class="swiper-lazy-preloader"></div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
<!--      <div class="ification">-->
<!--        <div class="content">-->
<!--          <div class="cont-left">-->
<!--            <ul>-->
<!--              <li class="cont-item" v-for="item in classInfo" :key="item.id">-->
<!--                <div class="item-left">-->
<!--                  <img :src="item.img" alt="" />-->
<!--                </div>-->
<!--                <div class="item-right">-->
<!--                  <p class="right1">{{ item.text }}</p>-->
<!--                  <p class="right2">{{ item.info }}</p>-->
<!--                </div>-->
<!--                <div class="infoBox">-->
<!--                  <ul class="item-info" v-if="item.children.length > 0">-->
<!--                    <li-->
<!--                      class="info-item"-->
<!--                      v-for="child in item.children"-->
<!--                      :key="child.id"-->
<!--                      @click="jumps(child,item.id)"-->
<!--                    >-->
<!--                      <div class="items">-->
<!--                        <img :src="child.img" alt="" />-->
<!--                      </div>-->
<!--                      <p>{{ child.text }}</p>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                  <div class="dengdai" v-else>-->
<!--                    <i class="iconfont icon-kafei"></i>-->
<!--                    <p>待上线...</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--          <div class="cont-right">-->
<!--            <div class="right-top">-->
<!--              <div class="headDiv">-->
<!--                <img src="@/static/imgs/icon_head_default.png" alt="" />-->
<!--              </div>-->
<!--              <div class="login">-->
<!--                <div class="login-sure" v-if="!loginTrue">-->
<!--                  <div class="lift">-->
<!--                    <span>Hi~</span>-->
<!--                    <span>{{ username }}</span>-->
<!--                  </div>-->
<!--                  <span @click="goOut">退出</span>-->
<!--                </div>-->
<!--                <div class="login-no" v-if="loginTrue">-->
<!--                  <span @click="login">登录</span>-->
<!--                  <div>&nbsp;|&nbsp;</div>-->
<!--                  <span @click="register">注册</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="right-bottom">-->
<!--              <p>会员专属优惠</p>-->
<!--              <ul>-->
<!--                <li>1.VIP服务，售后无忧</li>-->
<!--                <li>2.发票开具，保障权益</li>-->
<!--                <li>3.企业VI，随心定制</li>-->
<!--                <li>4.精选商品，快速便捷</li>-->
<!--                <li>5.一流印刷，保质保量</li>-->
<!--                <li>6.30天理赔，无后顾之忧</li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <!-- 爆款单品 -->
    <div class="popularSec">
      <div class="ban">
        <div class="dv1">
          <p class="p1">HOT SALE</p>
          <p class="p2">爆款单品</p>
          <span class="p3">RECOMMENDED</span>
          <p class="p4">>>>>>></p>
        </div>
        <!--        <div class="dv2" v-if="PopularItemList.length>0">-->
        <div class="dv2" v-for="item in PopularItemList" :key="item.productId">
          <div class="infoDiv">
            <p class="p1">{{ item.productName }}</p>
            <span></span>
            <p class="p2">个性化{{ item.productName }}定制</p>
            <div class="p3" @click="offer(item)">
              <i></i>
              <p>点击进入</p>
            </div>
          </div>
          <!--          <div class="imgDiv">-->
          <!--            <img :src="item.productPhoto" alt="" />-->
          <!--            <img src="@/static/imgs/img_sld2.png" alt="" />-->
          <!--          </div>-->
        </div>
        <!--        </div>-->
        <div
          class="bottom"
          v-if="PopularItemList.length == 0"
          style="
            text-align: center;
            font-size: 24px;
            padding: 20px 0;
            color: #000;
            margin: auto;
          "
        >
          暂无订单展示,敬请期待...
        </div>
      </div>
    </div>
    <!-- 行业资讯 -->
    <!-- <div class="infoSec">
      <div class="ban">
        <div class="header">
          <p class="p1">INDUSTRY INFORMATION</p>
          <p class="p2">行业资讯</p>
          <hr />
          <span>更多 >></span>
        </div>
        <div class="infoDiv">
          <div class="dv1">
            <div class="topic">
              <span>01</span>
              <p>行业资讯1</p>
              <hr />
            </div>
            <p class="info">
              行业资讯行业资讯行业资讯行业资讯行业资讯行业资讯行业资讯行业资讯行业资讯行业资讯行业资讯行业资讯行业资讯行业资...
            </p>
          </div>
          <div class="dv2">
            <div class="topic">
              <span>02</span>
              <p>行业资讯2</p>
              <hr />
            </div>
            <p class="info">行业资讯2</p>
          </div>
          <div class="dv3">
            <div class="topic">
              <span>03</span>
              <p>行业资讯3</p>
              <hr />
            </div>
            <p class="info">行业资讯3</p>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 印友公告 -->
    <div class="noticeSec">
      <div class="ban">
        <div class="header">
          <p class="p1">THE ANNOUNCEMENT</p>
          <p class="p2">新闻公告</p>
          <hr />
          <span
            class="moreNotice"
            v-if="notice.length > 0"
            @click="moreNotice('more')"
            >更多 >></span
          >
        </div>
        <div class="noticeDiv" v-if="noticeLength">
          <div
            v-for="item in notice"
            :key="item.id"
            @click="moreNotice(item.id)"
          >
            <div
              class="dv1"
              v-if="item.recommend === 1 && item.articleState === 1"
            >
              <div class="imgDiv">
                <img :src="item.extendColumn1" alt="" />
                <div class="muns">
                  <p>{{ item.time[2] }}</p>
                  <p>{{ item.time[1] }}.{{ item.time[0] }}</p>
                </div>
              </div>
              <div class="contentDiv">
                <div class="topic">{{ item.articleTitle }}</div>
                <!-- <p v-html="item.articleContent"></p> -->
              </div>
              <hr />
            </div>
          </div>
          <div class="last">
            <div class="dv1"></div>
          </div>
          <div class="last">
            <div class="dv1"></div>
          </div>
          <!-- <div class="dv1">
            <div class="imgDiv">
              <img src="@/static/imgs/img_notice_test.jpg" alt="" />
              <div class="muns">
                <p>15</p>
                <p>Jul.2017</p>
              </div>
            </div>
            <div class="contentDiv">
              <div class="topic">印友公告2</div>
              <p>印友公告信息2</p>
            </div>
            <hr />
          </div>
          <div class="dv1">
            <div class="imgDiv">
              <img src="@/static/imgs/img_notice_test.jpg" alt="" />
              <div class="muns">
                <p>30</p>
                <p>Jul.2017</p>
              </div>
            </div>
            <div class="contentDiv">
              <div class="topic">印友公告3</div>
              <p>印友公告信息3</p>
            </div>
            <hr />
          </div> -->
        </div>
        <div class="noticeDiv-two" v-else>暂无公告,敬请期待...</div>
      </div>
    </div>
    <!-- 实时订单 -->
    <div class="orderSec">
      <div class="ban">
        <div class="header">
          <p class="p1">REAL-TIME ORDER</p>
          <p class="p2">实时订单</p>
          <hr />
          <!-- <span>更多 >></span> -->
        </div>
        <div class="orderInfoDiv">
          <swiper
            class="swiper"
            :options="swiperOption1"
            v-if="webOrder.length > 0"
          >
            <swiper-slide
              class="swiper-slide"
              v-for="(item, index) in webOrder"
              :key="index"
            >
              <ul class="order swiper-lazy">
                <li v-for="order in item" :key="order.id">
                  <div class="info">
                    <div class="imgDiv">
                      <img :src="picUrls + order.productimage" alt="" />
                    </div>
                    <div class="contentDiv">
                      <p>产品名称：{{ order.ordername }}</p>
                      <p>下单时间：{{ order.ordertime }}</p>
                      <p>产品数量：{{ order.quantitydemanded }}</p>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="swiper-lazy-preloader"></div>
            </swiper-slide>
            <div class="swiper-pagination1" slot="pagination"></div>
          </swiper>
          <div class="bottom" v-else>暂无订单展示,敬请期待...</div>
          <!-- <ul class="order">
            <li v-for="item in webOrder" :key="item.id">
              <div class="info">
                <div class="imgDiv">
                  <img :src="item.productimage" alt="" />
                </div>
                <div class="contentDiv">
                  <p>产品名称：{{ item.ordername }}</p>
                  <p>下单时间：{{ item.ordertime }}</p>
                  <p>产品数量：{{ item.quantitydemanded }}</p>
                </div>
              </div>
            </li>
          </ul> -->
        </div>
        <div class="orderProcessDiv">
          <div class="desc">下单流程</div>
          <ul>
            <li v-for="item in desc" :key="item.id">
              <span>{{ item.inx }}</span>
              <p>{{ item.text }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { removeusername } from "../../api/myAuth";
// import { mapMutations, mapState } from "vuex";
import picUrl from "../../api/picture";
import api from "../../api/index/index";
export default {
  inject: ["reload"],
  data() {
    return {
      // swiper 轮播参数
      swiperOption: {
        loop: true,
        // autoplay: {
        //   // disableOnInteraction: false,
        // },
        autoplay: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
        },
        lazy: {
          lazy: true,
        },
      },
      swiperOption1: {
        loop: true,
        autoplay: {
          disableOnInteraction: false,
        },
        direction: "vertical",
        lazy: {
          lazy: true,
        },
      },
      desc: [
        {
          id: 0,
          inx: "01",
          text: "系统核价",
        },
        {
          id: 1,
          inx: "02",
          text: "在线下单",
        },
        {
          id: 2,
          inx: "03",
          text: "在线支付",
        },
        {
          id: 3,
          inx: "04",
          text: "智能生产",
        },
        {
          id: 4,
          inx: "05",
          text: "在线跟单",
        },
        {
          id: 5,
          inx: "06",
          text: "物流配送",
        },
        {
          id: 6,
          inx: "07",
          text: "产品验收",
        },
        {
          id: 7,
          inx: "08",
          text: "交付尾款",
        },
      ],
      webOrder: [],
      loginTrue: true, // 是否登录（状态管理）
      productMap: {
        '2028': 0,
        '2037': 0,
        '2020': 0,
        '2034': 0,
        '2015': 0,
        '2039': 0,
        '2036': 0,
        '2035': 0,
        '2032': 0,
        '2040': 0,
        '2041': 0,
        '2043': 0,
        '2042': 0,
        '1020': 1,
        '1028': 1,
        '1015': 1,
        '1032': 1,
        '1034': 1,
        '1023': 2,
        '1012': 2,
        '1016': 3,
        '1017': 3,
        '1018': 3,
        '1019': 3,
        '1013': 1,
        '1014': 1,
        '1035': 1,
        '1036': 1,
      },
      classInfo: [
        {
          id: 0,
          img: "icon_product10.png",
          text: "数码快印",
          info: "数码快印 小批量定制",
          children: [
            {
              id: 2028,
              img: "danye/DigitalSinglePage.jpg",
              text: "单页",
            },
            {
              id: 2037,
              img: "selfAdhesive/DigitalAdhesive1.jpg",
              text: "不干胶",
            },
            {
              id: 2020,
              img: "huace/DigitalAlbum1.jpg",
              text: "普通画册",
            },
            {
              id: 2034,
              img: "huace/DigitalAlbum2.jpg",
              text: "精装画册",
            },
            {
              id: 2015,
              img: "smallBatchCustomization/reticule2.jpg",
              text: "手提袋",
            },
            {
              id: 2039,
              img: "smallBatchCustomization/showbill2.jpg",
              text: "吊旗",
            },
            {
              id: 2026,
              img: "guali/DigitalCalendar1.jpg",
              text: "挂历",
            },
            {
              id: 2035,
              img: "taili.jpg",
              text: "台历",
            },
            {
              id: 2032,
              img: "fengtao.jpg",
              text: "封套",
            },
            {
              id: 2040,
              img: "xiezhen.jpg",
              text: "写真",
            },
            {
              id: 2041,
              img: "xxing.jpg",
              text: "X型展架",
            },
            {
              id: 2042,
              img: "yilabao.jpg",
              text: "易拉宝",
            },
            {
              id: 2043,
              img: "minpian.jpg",
              text: "名片",
            }
          ],
        },
        {
          id: 1,
          img: "icon_product01.png",
          text: "商务印刷",
          info: "手拎袋 画册",
          children: [
            {
              id: 1015,
              img: "img_sld3.png",
              text: "手提袋",
            },
            {
              id: 1020,
              img: "img_hc.png",
              text: "普通画册",
            },
            {
              id: 1034,
              img: "jingzhuangshu2.jpg",
              text: "精装画册",
            },
            // {
            //   id: 2,
            //   img: "img_jf.png",
            //   text: "教辅",
            // },
            {
              id: 1028,
              img: "img.zcool.jpg",
              text: "单页",
            },
            {
              id: 1032,
              img: "fengtao.jpg",
              text: "封套",
            },
            {
              id: 1013,
              img: "carbon/Carbon1.jpg",
              text: "无碳联单",
            },
            {
              id: 1014,
              img: "selfAdhesive/SelfAdhesive2.jpg",
              text: "不干胶",
            },
            {
              id: 1035,
              img: "taili.jpg",
              text: "台历",
            },
            {
              id: 1036,
              img: "guali.jpg",
              text: "挂历",
            },
          ],
        },
        {
          id: 2,
          img: "icon_product02.png",
          text: "产品包装",
          info: "单层卡纸彩盒 瓦楞彩箱",
          children: [
            {
              id: 1023,
              img: "img_ch.jpg",
              text: "单层卡纸彩盒",
            },
            {
              id: 1012,
              img: "img_cx.jpg",
              text: "瓦楞彩箱",
            },
          ],
        },
        {
          id: 3,
          img: "icon_product03.png",
          text: "后期加工",
          info: "喷画 条幅 标牌",
          children: [
            {
              id: 1016,
              img: "sprayPaint/SprayPainting.jpg",
              text: "喷画",
            },
            {
              id: 1017,
              img: "banner/Banner.jpg",
              text: "条幅锦旗绶带",
            },
            {
              id: 1018,
              img: "signs/Signs.jpg",
              text: "标识标牌",
            },
            {
              id: 1019,
              img: "signs/Signs2.jpg",
              text: "标牌现货",
            },
          ],
        },
        // {
        //   id: 4,
        //   img: "icon_product04.png",
        //   text: "办公系列",
        //   info: "无碳联单 不干胶",
        //   children: [
        //     {
        //       id: 1013,
        //       img: "carbon/Carbon1.jpg",
        //       text: "无碳联单",
        //     },
        //     {
        //       id: 1014,
        //       img: "selfAdhesive/SelfAdhesive2.jpg",
        //       text: "不干胶",
        //     },
        //   ],
        // },
        // {
        //   id: 5,
        //   img: "icon_product05.png",
        //   text: "生活常用",
        //   info: "挂历 台历",
        //   children: [
        //     {
        //       id: 1035,
        //       img: "taili.jpg",
        //       text: "台历",
        //     },
        //     {
        //       id: 1036,
        //       img: "guali.jpg",
        //       text: "挂历",
        //     },
        //   ],
        // },

      ],
      bannerList: [
        { img: "@/static/imgs/index_banner01.jpg" },
        { img: '"@/static/imgs/index_banner1.jpg"' },
      ],
      homeBannerList: [],
      username: "", //用户名
      notice: [],
      noticeLength: false, // 判断公告信息是否为发布状态并推荐 的公告信息个数
      // arr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], //测试分割数据

      //  爆款单品
      PopularItemList: [],
      picUrls: "",
      userId: "",
    };
  },
  computed: {
    // ...mapState(["userInfo"]),
  },
  created() {
    let obj = JSON.parse(window.sessionStorage.getItem("currentInfo"));
    let userObj = JSON.parse(window.sessionStorage.getItem("userInfo"));
    if (obj) {
      this.userId = obj.user_ID;
    }
    this.picUrls = picUrl.picUrl;
    console.log("图片地址", this.picUrls);
    // console.log(this.$route.query.ID)
    // let id=this.$route.query.ID
    // if(id==0){
    //   removeusername()
    // }else{

    this.getImg(this.classInfo);
    this.init(); // 获取banner图和公告
    this.HotMoney(); //爆款单品
    this.getIndexOrder(); // 获取实时订单
    if (userObj) {
      this.reload();
      this.username = userObj.username;
      this.loginTrue = false;
    } else {
      this.loginTrue = true;

      return;
    }

    // }
    // console.log("测试分割数组数据", this.sliceArray(this.arr, 4));
  },
  methods: {
    // 分割数组（用于实时订单循环）
    sliceArray(arr, length) {
      let index = arr.length;
      let newArray = [];
      for (let i = 0; i < index; i += length) {
        newArray.push(arr.slice(i, i + length));
      }
      return newArray;
    },
    // ...mapMutations(["setUserInfo"]),
    // initUserInfo(data) {
    //   this.$store.commit("setUserInfo", data);
    // },
    // 跳转到公告页面
    moreNotice(ids) {
      console.log(ids);
      this.$router.push({ name: "Proclamation", params: { id: ids } });
    },
    // 退出登录
    goOut() {
      removeusername();
      window.sessionStorage.removeItem("currentInfo");
      window.sessionStorage.removeItem("userInfo");
      window.sessionStorage.removeItem("nav");
      window.sessionStorage.removeItem("info");
      api.loginOut().then((res) => {
        console.log("退出成功", res);
        if (res.success) {
          this.$message.success("退出成功");
          this.loginTrue = true;
          this.reload();
          this.$router.push({ path: "/login" });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 首页基本信息获取
    init() {
      // 获取banner图
      api
        .getAllData(1, 10)
        .then((res) => {
          console.log("banner数据", res);
          if (res.success) {
            this.homeBannerList = res.data.rows;
          }
        })
        .catch((error) => {
          console.error("Error", error);
        });
      // 公告信息
      api
        .getArticle(1, 10)
        .then((res) => {
          console.log("获取公告信息", res);
          if (res.success) {
            if (res.data.list.length > 0) {
              res.data.list.map((el) => {
                el.time = this.handleTime(el.createTime);
              });
              let newArr = res.data.list.filter(
                (el) => el.recommend === 1 && el.articleState
              );
              console.log("筛选过后的数组", newArr);
              this.notice = newArr.slice(0, 3); // 截取数组，只展示3条
              let state = res.data.list.some(
                (el) => el.articleState === 1 && el.recommend === 1
              );
              if (state) {
                this.noticeLength = true;
              } else {
                this.noticeLength = false;
              }
              // console.log("处理后的数据", this.notice);
              // console.log("是否同时满足状态为发布和推荐", state);
            }
          }
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },
    // 获取网站订单列表
    getIndexOrder() {
      api
        .getOrder(20, 1)
        .then((res) => {
          console.log("获取首页网站实时订单", res);
          if (res.success) {
            this.webOrder = this.sliceArray(res.data.list, 4);
          } else {
            this.$message.error("获取实时订单失败");
          }
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },
    // 处理公告时间
    handleTime(time) {
      let times = time.substr(0, 10);
      let arr = times.split("-");
      return arr;
    },
    //爆款单品
    HotMoney() {
      this.$http.get("/home/getTopThreeProduct").then((res) => {
        console.log("爆款单品", res.data);
        if (res.data.code == 200) {
          this.PopularItemList = res.data.data;
        }
      });
    },
    // 登录跳转
    login() {
      this.$router.push({ path: "login" });
    },
    // 注册跳转
    register() {
      this.$router.push({ path: "register" });
    },
    // 递归动态加载本地图片
    getImg(arr) {
      console.log(arr);
      arr.forEach((el) => {
        console.log(el);
        el.img = require(`@/static/imgs/${el.img}`);
        let newArr = el.children;
        if (newArr) {
          this.getImg(newArr);
        } else {
          return;
        }
      });
    },
    // 印刷报价跳转
    jumps(item,pId) {
      if (this.userId === "") {
        // this.$message.error("请先进行登录");
        this.$router.push({ path: "/login" });
      } else {
        window.sessionStorage.setItem("leftMenu",item.id)
        window.sessionStorage.setItem("topMenu",pId)
        this.$router.push({ path: "Quotation"});
      }
    },

    // 爆款单品
    offer(item) {
      console.log(item);
      if (this.userId === "") {
        // this.$message.error("请先进行登录");
        this.$router.push({ path: "/login" });
      } else {
        window.sessionStorage.setItem("topMenu",this.productMap[item.productId])
        window.sessionStorage.setItem("leftMenu",item.productId)
        this.$router.push({ path: "Quotation"});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.coutents {
  width: 100%;
  .nav {
    width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    .type {
      cursor: pointer;
      width: 220px;
      height: 50px;
      background-color: #0289f2;
      color: #fff;
      line-height: 50px;
      font-size: 18px;
      text-align: center;
      i {
        font-size: 20px;
        margin-right: 10px;
      }
    }
    .nav-type {
      display: flex;
    }
    .nav-item {
      padding: 0 30px;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      color: #808080;
    }
    .nav-item:hover {
      color: #0289f2;
      cursor: pointer;
    }
  }
  .banner {
    position: relative;
    width: 100%;
    height: 420px;
    .img-box {
      width: 100%;
      height: 100%;
      .swiper {
        width: 100%;
        height: 100%;
        .swiper-slide {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .ification {
    width: 100%;
    // height: 420px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    .content {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      // height: 100%;
      margin: 0 auto;
      .cont-left {
        width: 220px;
        // height: 100%;
        background-color: #f4f4f4;
        position: relative;
        .cont-item {
          width: 220px;
          height: 90px;
          // display: flex;
          // align-items: center;
          .item-left {
            float: left;
            margin-top: 20px;
            img {
              width: 18px;
              height: 18px;
              margin: 0 10px 10px 10px;
            }
          }
          .item-right {
            padding-top: 20px;
            .right1 {
              font-size: 14px;
            }
            .right2 {
              font-size: 12px;
              color: #8f8f8f;
              margin-top: 5px;
            }
          }
          .infoBox {
            display: none;
            position: absolute;
            left: 220px;
            top: 1px;
            width: 480px;
            height: 600px;
            background-color: #ffffff;
            .item-info {
              box-sizing: border-box;
              padding: 15px;
              height: 100%;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              align-content: flex-start;
              .info-item {
                width: 42%;
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 10px;
                .items {
                  width: 100px;
                  height: 100%;
                  border: 1px solid #d9d9d9;
                  img {
                    display: block;
                    width: 50px;
                    height: 50px;
                    margin: 5px auto;
                  }
                }
                p {
                  font-size: 14px;
                  text-align: center;
                  max-width: 70px;
                }
                p:hover {
                  color: #0289f2;
                  cursor: pointer;
                }
              }
            }
            .dengdai {
              width: 100%;
              height: 420px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: #bfbfbf;
              i {
                font-size: 80px;
              }
            }
          }
        }
        .cont-item:hover {
          background-color: #fff;
        }
        .cont-item:hover .infoBox {
          display: block;
        }
      }
      .cont-right {
        margin-top: 1px;
        width: 220px;
        height: 330px;
        /*background-color: #f5f5f5;*/
        .right-top {
          width: 100%;
          height: 140px;
          padding-top: 10px;
          background-color: #f5f5f5;
          .headDiv {
            img {
              display: block;
              margin: 0 auto;
            }
          }
          .login {
            text-align: center;
            color: #888;
            font-size: 12px;
            margin-top: 5px;
            .login-sure {
              display: flex;
              justify-content: space-around;
              span {
                cursor: pointer;
              }
            }
            .login-no {
              div {
                display: inline-block;
                font-size: 17px;
                padding: 0 5px;
              }
              span {
                color: orangered;
                font-size: 17px;
                font-weight: bold;
              }
              span:hover {
                color: #0289f2;
                cursor: pointer;
              }
            }
          }
        }
        .right-bottom {
          padding: 10px 0;
          background-color: #f7fbff;
          font-size: 12px;
          text-align: center;
          color: #888;
          p {
            color: #0289f2;
          }
          ul {
            li {
              line-height: 24px;
            }
          }
        }
      }
    }
  }
  .popularSec {
   // padding: 30px 0;
    width: 100%;
    // height: 220px;
    color: #fff;
    background-color: #fafafa;
    .ban {
      width: 1200px;
      // height: 100%;
      margin: 0 auto;
      display: flex;
      .dv1 {
        width: 210px;
        padding-top: 40px;
        background-color: #6bbbf9;
        text-align: center;
        .p1 {
          font-size: 20px;
          line-height: 24px;
        }
        .p2 {
          font-size: 26px;
          line-height: 56px;
        }
        .p3 {
          padding: 0 2px;
          color: #0289f2;
          font-size: 14px;
          line-height: 28px;
          background-color: #fff;
        }
        .p4 {
          font-size: 16px;
          line-height: 56px;
        }
      }
      .dv2 {
        width: 330px;
        border-top: 3px solid #ff9600;
        background-color: #ffd59a;
      }
      .dv2:nth-child(2) {
        width: 330px;
        border-top: 3px solid #ed4d49;
        background-color: #e9d4c1;
      }
      .dv2:last-child {
        width: 330px;
        border-top: 3px solid #10c55b;
        background-color: #def9dc;
      }
      /*.dv3 {*/
      /*  width: 330px;*/
      /*  border-top: 3px solid #ed4d49;*/
      /*  background-color: #e9d4c1;*/
      /*}*/
      /*.dv4 {*/
      /*  width: 330px;*/
      /*  border-top: 3px solid #10c55b;*/
      /*  background-color: #def9dc;*/
      /*}*/
      .infoDiv {
        float: left;
        width: 154px;
        height: 180px;
        background: url("../../static/imgs/bg_orange_popular.png") no-repeat
          center;
        margin-left: 30px;
        text-align: center;
        width: calc(100% - 60px);
        background-size: 100% 100%;
        .p1 {
          padding-top: 25px;
          font-size: 22px;
          line-height: 32px;
        }
        span {
          display: block;
          width: 50px;
          margin: 0 auto;
          border: none;
          border-bottom: 2px solid #fff;
        }
        .p2 {
          font-size: 14px;
          line-height: 34px;
          margin-bottom: 8px;
        }
        .p3 {
          i {
            display: block;
            width: 30px;
            height: 30px;
            margin: 0 auto;
            background: url("../../static/imgs/icon_link_hot.png") no-repeat
              center;
            background-size: cover;
          }
          p {
            margin-top: 6px;
            font-size: 14px;
            line-height: 14px;
            text-align: center;
          }
        }
        .p3:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .imgDiv {
        float: left;
        margin: 40px 0 0 5px;
        img {
          width: 120px;
          height: 140px;
        }
      }
      .dv2:nth-child(2) .infoDiv {
        background: url("../../static/imgs/bg_red_popular.png") no-repeat center;
        width: calc(100% - 60px);
        background-size: 100% 100%;
      }
      .dv2:last-child .infoDiv {
        background: url("../../static/imgs/bg_green_popular.png") no-repeat
          center;
        width: calc(100% - 60px);
        background-size: 100% 100%;
      }
    }
  }
  // .infoSec {
  //   width: 100%;
  //   background-color: #fafafa;
  //   .ban {
  //     width: 1200px;
  //     margin: 0 auto;
  //     .infoDiv {
  //       width: 100%;
  //       display: flex;
  //       justify-content: space-between;
  //       flex-wrap: wrap;
  //       .dv1,
  //       .dv2,
  //       .dv3 {
  //         box-sizing: border-box;
  //         position: relative;
  //         width: 374px;
  //         height: 227px;
  //         padding: 42px 30px 0;
  //         border: 1px solid #d9d9d9;
  //         background-color: #fff;
  //         box-shadow: 0 0 5px 2px rgb(0 0 0 / 15%);
  //         .topic {
  //           position: absolute;
  //           span {
  //             position: absolute;
  //             left: -14px;
  //             top: -12px;
  //             font-family: Arial;
  //             font-size: 40px;
  //             font-weight: bold;
  //             color: rgba(48, 192, 246, 0.23);
  //           }
  //           p {
  //             width: 320px;
  //             font-size: 18px;
  //             line-height: 42px;
  //             color: #4c4c4c;
  //             white-space: nowrap;
  //             overflow: hidden;
  //             text-overflow: ellipsis;
  //           }
  //           hr {
  //             position: absolute;
  //             bottom: 0;
  //             left: 0;
  //             width: 68px;
  //             border: none;
  //             border-bottom: 2px solid #0289f2;
  //           }
  //         }
  //         .info {
  //           white-space: wrap;
  //           overflow: hidden;
  //           text-overflow: ellipsis;
  //           margin-top: 60px;
  //           font-size: 14px;
  //           line-height: 24px;
  //           color: #999;
  //         }
  //       }
  //     }
  //   }
  // }
  .noticeSec {
    width: 100%;
    background-color: #fafafa;
    .ban {
      width: 1200px;
      margin: 0 auto;
      .header {
        .moreNotice {
          cursor: pointer;
        }
      }
      .noticeDiv {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .dv1 {
          box-sizing: border-box;
          position: relative;
          width: 374px;
          height: 338px;
          border: 1px solid #d9d9d9;
          background-color: #fff;
          box-shadow: 0 0 5px 2px rgb(0 0 0 / 15%);
          .imgDiv {
            position: relative;
            width: 100%;
            height: 218px;
            img {
              display: block;
              width: 100%;
              max-height: 218px;
            }
          }
          .muns {
            position: absolute;
            left: 19px;
            bottom: -16px;
            width: 72px;
            height: 72px;
            padding-top: 10px;
            background-color: #0289f2;
            color: #fff;
            text-align: center;
            p:first-child {
              font-size: 32px;
              font-weight: bold;
            }
            p:last-child {
              font-size: 14px;
            }
          }
          .contentDiv {
            padding: 22px 19px 0;
            .topic {
              width: 338px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 18px;
              line-height: 40px;
              color: #4c4c4c;
            }
            p {
              max-height: 75px;
              font-size: 14px;
              line-height: 24px;
              color: #999;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          hr {
            position: absolute;
            bottom: 15px;
            right: 18px;
            width: 48px;
            border: none;
            border-bottom: 2px solid #0289f2;
          }
        }
        .last {
          .dv1 {
            height: 0;
            box-shadow: none;
            border: none;
          }
        }
      }

      .noticeDiv-two {
        text-align: center;
        font-size: 24px;
        padding: 20px 0;
      }
    }
  }
  .orderSec {
    width: 100%;
    background-color: #fafafa;
    padding-bottom: 40px;
    border-bottom: 1px solid #809090;
    .ban {
      width: 1200px;
      margin: 0 auto;
      .orderInfoDiv {
        width: 1200px;
        margin: 0 auto;
        height: 344px;
        overflow: hidden;
        .swiper {
          width: 100%;
          height: 100%;
          .swiper-slide {
            width: 100%;
            height: 100%;
          }
        }
        .order {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            width: 560px;
            height: 172px;
            background: url("../../static/imgs/img_orderInfoBox.png") no-repeat
              bottom;
            .info {
              box-sizing: border-box;
              display: table;
              height: 130px;
              padding: 20px 26px;
              display: flex;
              .imgDiv {
                display: table-cell;
                width: 90px;
                height: 90px;
                vertical-align: middle;
                /*background-color: #f1f1f1;*/
                img {
                  display: block;
                  height: 90%;
                  margin: auto;
                  width: 81px;
                  height: 81px;
                }
              }
              .contentDiv {
                width: 420px;
                padding: 10px 0 10px 18px;
                display: table-cell;
                vertical-align: top;
                p {
                  font-size: 16px;
                  color: #999;
                  line-height: 25px;
                }
              }
            }
          }
        }
        .bottom {
          text-align: center;
          font-size: 24px;
          padding: 20px 0;
        }
      }
      .orderProcessDiv {
        display: flex;
        align-items: center;
        width: 100%;
        height: 140px;
        margin-top: 8px;
        background-color: #fff;
        border-top: 1px solid #0289f2;
        border-bottom: 8px solid #0289f2;
        .desc {
          font-size: 24px;
          color: #0289f2;
          line-height: 132px;
          padding: 0 28px;
        }
        ul {
          display: table-cell;
          li {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            span {
              display: block;
              margin: 0 auto;
              width: 44px;
              height: 44px;
              font-size: 20px;
              line-height: 44px;
              color: #0289f2;
              text-align: center;
              background: url("../../static/imgs/bg_circle.png") no-repeat
                center;
              background-size: cover;
            }
            p {
              margin-top: 20px;
              font-size: 18px;
              color: #777;
              text-align: center;
            }
          }
          li:not(:first-child) {
            margin-left: 58px;
          }
          li:not(:first-child):before {
            position: absolute;
            display: block;
            width: 28px;
            height: 12px;
            top: 0;
            bottom: 0;
            left: -43px;
            margin: auto 0;
            content: url("../../static/imgs/icon_arrow_process.png");
          }
        }
      }
    }
  }
  // 公共头部信息
  .header {
    position: relative;
    padding: 20px 0 40px 0;
    text-align: center;
    .p1 {
      font-size: 26px;
      line-height: 36px;
      color: #4c4c4c;
      font-weight: bold;
    }
    .p2 {
      font-size: 24px;
      line-height: 34px;
      color: #4c4c4c;
    }
    hr {
      margin: 5px auto 0;
      width: 68px;
      border: none;
      border-bottom: 2px solid #0289f2;
    }
    span {
      height: 16px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto 0;
      font-size: 14px;
      line-height: 16px;
      color: #4c4c4c;
    }
  }
}
</style>
